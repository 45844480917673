import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/app/src/layout.tsx";
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const Fragment = makeShortcode("Fragment");
const Content = makeShortcode("Content");
const ContentColumns = makeShortcode("ContentColumns");
const Card = makeShortcode("Card");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <Fragment mdxType="Fragment">
      <Content mdxType="Content">
        <h2>{`Splnený sen a naše začiatky`}</h2>
        <blockquote>
          <p parentName="blockquote">{`Je to náš splnený sen. Som rodákom z Vernára a v lyžiarskom stredisku Vernár som pôsobil od detstva. Tu som sa naučil lyžovať a tento kraj je môjmu srdcu veľmi blízky. Preto sme sa s rodinou rozhodli, že si tu otvoríme reštauráciu. Neskôr sme naše plány ešte “rozšírili” a rozhodli sa otvoriť hotel.`}</p>
          <footer>Ján Alexa, starší, hlava rodiny.</footer>
        </blockquote>
        <p>{`Práce pri výstavbe si naša rodina realizovala svojpomocne, k stavebníctvu máme blízko. V každom kúsku, v každom “rohu” hotela sa odráža naša práca a srdce, ktoré sme do prác vložili.
V zime roku 2016 padlo rozhodnutie. Hoci ubytovacia časť ani vonkajšia fasáda nie sú 100% dokončené, otvoríme reštauráciu. V lyžiarskej sezóne 2016/2017 sme tak privítali a zasýtili prvých hostí. A nebolo ich málo :)
Po skončení lyžiarskej sezóny prišiel čas na dokončovacie práce. Ďalšia sezóna už patrila nielen reštaurácii, ale aj hotelu. V zime 2017 sme privítali aj prvých ubytovaných hostí.`}</p>
        <p>{`Náš hotel Studničky je rodinný hotel, do ktorého sme vložili svoju dušu a veríme, že vrúcnu a rodinnú atmosféru pocíti každý náš hosť.`}</p>
        <h2>{`Vybavenie hotela`}</h2>
        <ul>
          <li parentName="ul">{`14 hotelových izieb a apartmánov s kapacitou 40 osôb`}</li>
          <li parentName="ul">{`reštaurácia s kapacitou 100 osôb`}</li>
          <li parentName="ul">{`konferenčná miestnosť s kapacitou 50 osôb`}</li>
          <li parentName="ul">{`požičovňa a úschovňa lyžiarskych potrieb priamo v hoteli`}</li>
          <li parentName="ul">{`detský kútik a spoločenské hry pre rodiny dostupné na recepcii`}</li>
          <li parentName="ul">{`exteriérová vírivka`}</li>
        </ul>
      </Content>
      <h2>{`Napísali o nás`}</h2>
      <ContentColumns mdxType="ContentColumns">{[<Card key={1} image="restauracia/restauracia.jpg" title="Vo Vernári otvorili reštauráciu Studničky plnú domácich lahôdok" to="https://magazin.lionline.sk/vo-vernari-otvorili-restauraciu-studnicky-plnu-domacich-lahodok/" mdxType="Card">
		Novootvorená reštaurácia Studničky v malebnej obci Vernár ponúka chutnú domácu kuchyňu ako od babičky.
	</Card>, <Card key={2} image="hotel/studnicky.jpg" title="Sedem dôvodov, prečo navštíviť rodinný hotel Studničky vo Vernári" to="https://magazin.lionline.sk/sedem-dovodov-preco-navstivit-rodinny-hotel-studnicky-vo-vernari/" mdxType="Card">
		Zabudnite na každodenné starosti a navštívte výnimočný domáci hotel Studničky nachádzajúci sa v lone krásnej prírody Slovenského Raja.
	</Card>, <Card key={3} image="hotel/IMG_5057.jpg" title="Okolie hotela Studničky láka mnoho turistov aj počas letnej sezóny" to="https://magazin.lionline.sk/okolie-hotela-studnicky-laka-mnoho-turistov-aj-pocas-letnej-sezony/" mdxType="Card">
		Okolie hotela Studničky ponúka veľké množstvo lákadiel, ktoré ocenia najmä vyznávači turistiky či spoznávania krás Slovenska.
	</Card>]}</ContentColumns>
    </Fragment>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      